export default [
  {
    path: '/',
    name: 'home',
    component: () => import('./../views/MilestonesOverview')
  },
  {
    path: '/reports/tasks',
    name: 'report-tasks',
    component: () => import('./../views/TasksReport')
  },
  {
    path: '/reports/health',
    name: 'health report',
    component: () => import('./../views/Health')
  },
  {
    path: '/inventions',
    name: 'inventions',
    component: () => import('./../views/Inventions')
  },
  {
    path: '/milestones/overview',
    name: 'milestones overview',
    component: () => import('./../views/MilestonesOverview')
  },
  {
    path: '/inventions/:id',
    name: 'invention',
    component: () => import('./../views/Inventions'),
    children: [
      { path: '', component: () => import('../components/inventions/pages/Tasks') },
      { path: 'stream', component: () => import('../components/inventions/pages/Stream') },
      { path: 'ifw', component: () => import('../components/inventions/pages/IFW') },
      { path: 'messages', component: () => import('../components/inventions/pages/Messages') },
      { path: 'emails', component: () => import('../components/inventions/pages/Emails') },
      { path: 'docketing', component: () => import('../components/inventions/pages/Docketing') },
      { path: 'files', component: () => import('../components/inventions/pages/Files') }
    ]
  },
  {
    path: '/boards',
    name: 'boards',
    component: () => import('./../views/Boards')
  },
  {
    path: '/boards/:id',
    name: 'board',
    component: () => import('./../views/Board')
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('./../views/Tasks')
  },
  {
    path: '/tasks/:id',
    name: 'task',
    component: () => import('./../views/Tasks')
  },
  {
    path: '/applications',
    name: 'applications',
    component: () => import('./../views/Applications')
  },
  {
    path: '/:catchAll(.*)',
    name: 'not found',
    component: () => import('./../components/NotFound')
  },
  {
    path: '/minions',
    name: 'minions-lair',
    component: () => import('./../views/Minions')
  },
  {
    path: '/minions/new',
    component: () => import('./../views/Minion'),
    props: {
      newMinion: true
    },
    children: [{ path: '', component: () => import('../components/minions/pages/MinionPage.vue') }]
  },
  {
    path: '/minions/:id',
    name: 'minion-page',
    component: () => import('./../views/Minion'),
    children: [
      { path: '', component: () => import('../components/minions/pages/MinionPage') },
      { path: 'tasks', name: 'minion-tasks', component: () => import('../components/minions/pages/Tasks') },
      { path: 'chat', component: () => import('../components/minions/pages/Chat') },
      { path: 'feedback', component: () => import('../components/minions/pages/Feedback') }
    ]
  }
];
