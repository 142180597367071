import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const UPDATE_MINION = am(`UPDATE_MINION`);
const GET_MINIONS = am(`GET_MINIONS`);
const GET_MINION = am(`GET_MINION`);
const ADD_TO_MINIONS = am(`ADD_TO_MINIONS`);
const REMOVE_FROM_MINIONS = am(`REMOVE_FROM_MINIONS`);

export default {
  namespaced: true,
  ...factory({
    state: {
      collection: {},
      minion: {},
      isGetMinionsRequestPending: false,
      isGetMinionsRequestFailed: false,
      isGetMinionRequestPending: false,
      isGetMinionRequestFailed: false,
      isUpdateMinionRequestPending: false,
      isUpdateMinionRequestFailed: false,
      isAddMinionRequestPending: false,
      isAddMinionRequestFailed: false,
      isRemoveMinionRequestPending: false,
      isRemoveMinionRequestFailed: false
    },
    mutations: {
      [GET_MINIONS.STARTED](state) {
        state.isGetMinionsRequestFailed = false;
        state.isGetMinionsRequestPending = true;
        state.collection = [];
      },
      [GET_MINIONS.COMPLETED](state, collection) {
        state.collection = collection;

        state.isGetMinionsRequestPending = false;
      },
      [GET_MINIONS.FAILED](state) {
        state.isGetMinionsRequestFailed = true;
        state.isGetMinionsRequestPending = false;
      },
      [GET_MINION.STARTED](state) {
        state.isGetMinionRequestFailed = false;
        state.isGetMinionRequestPending = true;
        state.minion = {};
      },
      [GET_MINION.COMPLETED](state, minion) {
        state.isGetMinionRequestPending = false;
        state.minion = minion;
      },
      [GET_MINION.FAILED](state) {
        state.isGetMinionsRequestFailed = true;
        state.isGetMinionsRequestPending = false;
      },
      [ADD_TO_MINIONS.STARTED](state) {
        state.isAddMinionRequestPending = true;
        state.isAddMinionRequestFailed = false;
      },
      [ADD_TO_MINIONS.COMPLETED](state, item) {
        if (Array.isArray(state?.collection?.data) && state?.collection?.data.length !== 0) {
          state.collection.data.push(item);
        }

        state.minion = { ...item };

        state.isAddMinionRequestPending = false;
      },
      [ADD_TO_MINIONS.FAILED](state) {
        state.isAddMinionRequestPending = false;
        state.isAddMinionRequestFailed = true;
      },
      [UPDATE_MINION.STARTED](state) {
        state.isUpdateMinionRequestPending = true;
        state.isUpdateMinionRequestFailed = false;
      },
      [UPDATE_MINION.COMPLETED](state, newMinion) {
        state.isUpdateMinionRequestPending = false;
        state.minion = { ...newMinion };
      },
      [UPDATE_MINION.FAILED](state) {
        state.isUpdateMinionRequestPending = false;
        state.isUpdateMinionRequestFailed = true;
      },
      [REMOVE_FROM_MINIONS.STARTED](state) {
        state.isRemoveMinionRequestPending = true;
        state.isRemoveMinionRequestFailed = false;
      },
      [REMOVE_FROM_MINIONS.COMPLETED](state, id) {
        if (!state?.collection?.data) {
          state.isRemoveMinionRequestPending = false;
        } else {
          state.collection.data = state.collection.data.filter(item => item.documentId !== id);
          state.collection.meta.pagination.total = state.collection.data.length;
          state.isRemoveMinionRequestPending = false;
        }
      },
      [REMOVE_FROM_MINIONS.FAILED](state) {
        state.isRemoveMinionRequestPending = false;
        state.isRemoveMinionRequestFailed = true;
      }
    },
    actions: {
      async getMinions({ commit }, { pageSize, page, query, startDate, endDate, sortBy, sortDirection }) {
        try {
          commit(GET_MINIONS.STARTED);

          const queryParams = new URLSearchParams();

          queryParams.set('pagination[page]', page);
          queryParams.set('pagination[pageSize]', pageSize);

          if (sortBy) {
            queryParams.set('sort', `${sortBy}:${sortDirection.toLocaleLowerCase()}`);
          }

          if (query && query.length > 0) {
            queryParams.set('filters[name]', query);
            queryParams.set('filters[trainer]', query);
            queryParams.set('filters[manager]', query);
          }

          queryParams.set('filters[startDate]', startDate);
          queryParams.set('filters[endDate]', endDate);

          const collection = await httpClient.get(`/api/streams/minion-dojo-api/api/minions/statistics?${queryParams.toString()}`);

          commit(GET_MINIONS.COMPLETED, collection);
        } catch (e) {
          commit(GET_MINIONS.FAILED);
          throw e;
        }
      },
      async getMinion({ commit }, id) {
        try {
          commit(GET_MINION.STARTED);
          const minion = await httpClient.get(`/api/streams/minion-dojo-api/api/minions/${id}?populate=*`);
          commit(GET_MINION.COMPLETED, minion.data);
        } catch (e) {
          commit(GET_MINION.FAILED);
          throw e;
        }
      },
      async addMinion({ commit }, minion) {
        try {
          commit(ADD_TO_MINIONS.STARTED);

          const response = await httpClient.post(`/api/streams/minion-dojo-api/api/minions`, { data: minion });

          if (!response.data) {
            throw new Error(`Failed to add new item with name: ${minion.name}`);
          }

          commit(ADD_TO_MINIONS.COMPLETED, response.data);

          return response.data;
        } catch (e) {
          commit(ADD_TO_MINIONS.FAILED);
          throw e;
        }
      },
      async removeMinion({ commit }, minionId) {
        try {
          commit(REMOVE_FROM_MINIONS.STARTED);

          await httpClient.delete(`/api/streams/minion-dojo-api/api/minions/${minionId}`);

          commit(REMOVE_FROM_MINIONS.COMPLETED, minionId);
        } catch (e) {
          commit(REMOVE_FROM_MINIONS.FAILED);
          console.log(e);
          throw e;
        }
      },
      async updateMinion({ commit }, { updatedMinion, minionId }) {
        try {
          commit(UPDATE_MINION.STARTED);

          if (!minionId) {
            throw new Error('No minion id present');
          }

          const updated = await httpClient.put(`/api/streams/minion-dojo-api/api/minions/${minionId}`, {
            data: updatedMinion
          });

          const updatedMinionData = await httpClient.get(
            `/api/streams/minion-dojo-api/api/minions/${updated?.data?.documentId ?? minionId}?populate=*`
          );

          commit(UPDATE_MINION.COMPLETED, updatedMinionData.data);
        } catch {
          commit(UPDATE_MINION.FAILED);
        }
      }
    }
  })('minions')
};
