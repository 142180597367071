<template>
  <section class="shell" :class="{ preview: previewMode }">
    <aside v-if="!previewMode">
      <nav>
        <ul class="nav-list">
          <li class="nav-list-item">
            <router-link
              to="/milestones/overview"
              class="nav-list-item-link"
              :class="{ 'router-link-clicked': $route.path === '/' || $route.path.startsWith('/milestones/overview') }"
            >
              <p-icon name="playlist-star" size="lg"></p-icon>
              <span class="nav-list-item-title">Milestones</span>
            </router-link>
          </li>
          <li class="nav-list-item">
            <router-link to="/boards" :class="{ 'router-link-clicked': $route.path.startsWith('/boards') }" class="nav-list-item-link">
              <p-icon name="view-week" size="lg"></p-icon>
              <span class="nav-list-item-title">Boards</span>
            </router-link>
          </li>
          <li class="nav-list-item">
            <router-link
              to="/inventions"
              class="nav-list-item-link"
              :class="{
                'router-link-clicked': $route.path.startsWith('/inventions') && !$route.path.endsWith('/my')
              }"
            >
              <p-icon name="view-split-vertical" size="lg"></p-icon>
              <span class="nav-list-item-title">Inventions</span>
            </router-link>
          </li>
          <li class="nav-list-item">
            <router-link to="/tasks" class="nav-list-item-link">
              <p-icon name="feature-search" size="lg"></p-icon>
              <span class="nav-list-item-title">Search</span>
            </router-link>
          </li>
          <li class="nav-list-item">
            <router-link to="/minions" class="nav-list-item-link">
              <svg viewBox="0 0 100 100" width="40" height="30" xmlns="http://www.w3.org/2000/svg">
                <!-- Body with even outline -->
                <path
                  d="M30 10 
                         Q50 0 70 10 
                         Q80 20 80 40 
                         V80 
                         Q80 90 70 90 
                         H30 
                         Q20 90 20 80 
                         V40 
                         Q20 20 30 10 
                         Z"
                  fill="white"
                  stroke="#424242"
                  stroke-width="2"
                />

                <!-- Goggle strap -->
                <rect x="10" y="30" width="80" height="5" fill="#424242" rx="2.5" />

                <!-- Left goggle -->
                <circle cx="40" cy="35" r="10" fill="#B0BEC5" />
                <circle cx="40" cy="35" r="5" fill="#000" />
                <circle cx="42" cy="33" r="1.5" fill="white" />

                <!-- Right goggle -->
                <circle cx="60" cy="35" r="10" fill="#B0BEC5" />
                <circle cx="60" cy="35" r="5" fill="#000" />
                <circle cx="62" cy="33" r="1.5" fill="white" />

                <!-- Smile -->
                <path d="M42 65 Q50 70 58 65" stroke="#424242" stroke-width="2" fill="none" stroke-linecap="round" />
              </svg>

              <span class="nav-list-item-title">Minion Lair</span>
            </router-link>
          </li>
          <div v-if="pinnedLinks.length" class="separator"></div>
          <li v-for="link in pinnedLinks" :key="link.to" :title="link.title" class="nav-list-item">
            <router-link :to="link.to" :class="{ 'router-link-clicked': $route.fullPath === link.to }" class="nav-list-item-link">
              <p-icon :name="link.icon" size="lg"></p-icon>
              <span class="nav-list-item-title">{{ link.title }}</span>
              <span class="nav-list-item-title">{{ link.subrow }}</span>

              <p-icon class="unpin-icon" size="sm" name="pin-outline" />
            </router-link>
          </li>

          <li class="nav-list-item" style="position: absolute; bottom: 5px; left: 50%; transform: translateX(-50%)">
            <router-link to="/applications">
              <p-icon name="download" size="lg"></p-icon>
            </router-link>
          </li>
        </ul>
      </nav>
    </aside>

    <router-view />

    <div v-if="!previewMode && settingsPanelShown && settingToEdit" class="pane-wrapper">
      <Pane :name="'Settings'" :title="settingToEdit.formTitle" :data="settingToEdit" @close="closeSettings" />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Icon from './common/Icon';
import Pane from '@/components/inventions/Pane.vue';

export default {
  components: {
    'p-icon': Icon,
    Pane
  },
  computed: {
    ...mapState({
      previewMode: s => s.previewMode || s.fipMode,
      settingsPanelShown: s => s.settings.panelShown,
      settingToEdit: s => s.settings.current,
      pinnedLinks: s => s.pinnedLinks
    })
  },
  methods: {
    closeSettings() {
      this.$store.dispatch('settings/togglePanel');
    },
    unpin(e, item) {
      e.stopPropagation();
      e.preventDefault();
      this.$store.dispatch('TOGGLE_PINNED_LINK', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.pane-wrapper {
  width: 300px;
  background: var(--theme-surface);
}

.shell {
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr) auto;
  grid-template-rows: minmax(0, 1fr);

  &.preview {
    grid-template-columns: 1fr;
  }

  width: 100%;
  height: 100%;

  aside {
    color: var(--theme-on-surface);
    background-color: var(--theme-surface);
    width: 50px;
    display: grid;
    overflow: visible;
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
    margin-right: 0.5rem;
    nav {
      overflow: visible;
      position: relative;
    }
    .nav-list {
      display: grid;
      font-size: 1rem;
      padding: 0;
      align-items: center;
      justify-content: center;
      grid-gap: 0.5rem;
      grid-template-rows: repeat(4, 50px) minmax(0, 1fr);
      grid-template-columns: minmax(0, 1fr);
      .separator {
        border-bottom: 2px solid var(--theme-on-surface);
        margin: 0 5px;
      }
      .nav-list-item {
        // text-transform: uppercase;

        position: relative;

        .nav-list-item-link {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .nav-list-item-title {
          font-size: 8px;
          padding: 0 3px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          word-break: break-word;
        }

        .unpin-icon {
          position: absolute;
          top: 17px;
          right: 3px;
          transform: rotate(45deg);
        }
        a {
          i {
            color: var(--theme-on-surface);
          }

          &.router-link-clicked {
            cursor: default;
            i {
              color: var(--theme-primary);
            }
          }

          &.router-link-clicked ~ .nav-list-item-anc {
            cursor: default;
            i {
              color: var(--theme-primary);
            }
          }
        }

        > span {
          font-size: 1.5rem;
          display: inline-flex;
          margin-right: 5px;
          width: 30px;
          height: 100%;
          align-items: center;
        }

        .nav-list-item-anc {
          cursor: pointer;
          &.active {
            i {
              color: var(--theme-primary-accent);
            }
          }
        }
        .nav-list-item-anc ~ .nav-list-item-sub {
          position: absolute;
          left: 3.35rem;
          z-index: 1;
          top: -2rem;
          transform: translateX(-5px);
          padding-left: 5px;
          max-width: 250px;
          min-width: 150px;
          > section {
            background: var(--theme-surface);
            border: 1px solid var(--theme-highlight);
            border-radius: 2px;
            padding: 0;
          }
          &:after {
            position: absolute;
            content: '';
            border-width: 5px;
            border-color: transparent var(--theme-highlight) transparent transparent;
            border-style: solid;
            top: 2.8rem;
            left: 0;
            transform: translate(-5px, -50%);
            z-index: 1;
          }
        }

        &:hover {
          .nav-list-item-anc ~ .nav-list-item-sub {
            display: block;
          }
        }
      }
    }

    footer {
      display: flex;
      width: 100%;

      justify-content: center;
      align-items: center;
    }

    &.expanded {
      width: 250px;

      footer {
        justify-content: flex-end;
      }
    }
  }

  // aside ~ section {
  //   width: 100%;
  //   height: 100%;
  //   display: flex;
  //   flex: 1;
  //   overflow-y: auto;
  // }
}
</style>
